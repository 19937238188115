import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { InfenoxDiffData } from '../../../data/about-db';

export default function InfenoxDifference() {
  return (
    <section className="mt-2 mb-5">
      <Container>
        <Row className="text-center">
          <h2 className="pb-2">
            {InfenoxDiffData[0].heading}
            <span className="infenox-text-highlight team_height" />

          </h2>

          {InfenoxDiffData[0].data.map(({
            id, name, image, text,
          }) => (
            <Col className="col-12 col-lg-6 mt-3" key={id}>
              <Row className="infenox-difference-card cardcustom h-100">
                <Col className="col-2 text-center">
                  <img src={image} alt={name} />
                </Col>
                <Col className="col-10">
                  <h4>{name}</h4>
                  <p className="custom-text-justify">{text}</p>
                </Col>
              </Row>
            </Col>
          ))}

        </Row>
      </Container>
    </section>
  );
}
